
import { Vue, Options } from 'vue-class-component';
import debounce from 'lodash-es/debounce';
import { BaseTextarea, ManagementPlanDiagnosesGroup } from '@/lib/components';
import { ManagementPlan, ManagementPlanDestination, ManagementPlanStepParam, ManagementPlanTimeframe } from '@/models';
import { ManagementPlanService } from '@/services/api';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    organisationId: {
      type: String,
      required: true
    },
    patientId: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      required: true
    },
    managementPlanId: {
      type: String,
      required: true
    },
    completeManagement: {
      type: Boolean,
      default: false
    }
  },
  components: { ManagementPlanDiagnosesGroup, BaseTextarea }
})
export default class Management extends Vue {
  patientId!: string;
  organisationId!: string;
  reviewId!: string;
  managementPlanService = new ManagementPlanService(this.patientId);
  managementPlan: ManagementPlan | null = null;
  timeframes: Array<ManagementPlanTimeframe> = [];
  destinations: Array<ManagementPlanDestination> = [];
  loading = false;
  completeManagement = false;
  notificationStore = useNotificationStore();

  async mounted() {
    try {
      this.loading = true;
      await this.getTimeframes();
      await this.getDestinations();
      this.managementPlan = await this.startNewManagementPlan();
      this.$emit('update:managementPlanId', this.managementPlan.id);
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    } finally {
      this.loading = false;
      // when completeManagement === true, mark the plan to 'complete'.
      this.$watch('completeManagement', () => this.completeManagementPlan());
    }
  }

  async startNewManagementPlan(): Promise<ManagementPlan> {
    return (
      await this.managementPlanService.create({
        encounter_id: this.encounterId,
        organisational_unit_id: this.organisationId
      })
    ).data;
  }

  async addStep(params: ManagementPlanStepParam) {
    if (this.managementPlan) {
      try {
        this.loading = true;
        await this.managementPlanService.addManagementPlanStep(this.managementPlan.id, params.action_id, params);
        await this.refreshLocalManagementPlan();
      } catch (e) {
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.management.add-step-error')
        });
        await this.refreshLocalManagementPlan();
      } finally {
        this.loading = false;
      }
    }
  }

  async updateStep(params: ManagementPlanStepParam) {
    if (this.managementPlan) {
      try {
        this.loading = true;
        await this.managementPlanService.updateManagementPlanStep(
          this.managementPlan.id,
          params.action_id,
          params.step_id,
          params
        );
        await this.refreshLocalManagementPlan();
      } catch (e) {
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.management.update-step-error')
        });
        await this.refreshLocalManagementPlan();
      } finally {
        this.loading = false;
      }
    }
  }

  async deleteStep(args: { action_id: string; step_id: string }) {
    if (this.managementPlan) {
      try {
        this.loading = true;
        await this.managementPlanService.deleteManagementPlanStep(this.managementPlan.id, args.action_id, args.step_id);
        await this.refreshLocalManagementPlan();
      } catch (e) {
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.management.delete-step-error')
        });
        await this.refreshLocalManagementPlan();
      } finally {
        this.loading = false;
      }
    }
  }

  get updateManagementPlanMarkdownDebounce() {
    return debounce(() => this.updateManagementPlanMarkdown(), 500);
  }

  async updateManagementPlanMarkdown() {
    if (this.managementPlan) {
      try {
        this.loading = true;
        await this.managementPlanService.update(this.managementPlan.id, {
          review_summary_markdown: this.managementPlan.review_summary_markdown
        });
        await this.refreshLocalManagementPlan();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }

  async completeManagementPlan() {
    if (this.managementPlan) {
      try {
        this.loading = true;
        await this.managementPlanService.update(this.managementPlan.id, {
          completed: true
        });
        await this.refreshLocalManagementPlan();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }

  async refreshLocalManagementPlan() {
    if (this.managementPlan) {
      this.managementPlan = await this.managementPlanService.fetch(this.managementPlan.id);
    }
  }

  async getTimeframes() {
    this.timeframes = (await this.managementPlanService.getManagementPlanTimeframesList()).data;
  }

  async getDestinations() {
    this.destinations = (await this.managementPlanService.getManagementPlanDestinationsList()).data;
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-16" }
const _hoisted_2 = { class: "mb-12" }
const _hoisted_3 = { class: "text-2xl font-bold text-gray-900" }
const _hoisted_4 = { class: "mt-1 text-lg text-gray-500 font-regular" }
const _hoisted_5 = {
  key: 0,
  class: "p-8 rounded bg-gray-100"
}
const _hoisted_6 = { class: "mt-12 mb-6 text-lg font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManagementPlanDiagnosesGroup = _resolveComponent("ManagementPlanDiagnosesGroup")!
  const _component_BaseTextarea = _resolveComponent("BaseTextarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.management.plan')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.management.instructions')), 1)
    ]),
    (_ctx.managementPlan)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.managementPlan.actions, (action, actionIndex) => {
            return (_openBlock(), _createElementBlock("div", { key: actionIndex }, [
              _createVNode(_component_ManagementPlanDiagnosesGroup, {
                value: _ctx.managementPlan.actions[actionIndex],
                "action-id": action.id,
                timeframes: _ctx.timeframes,
                destinations: _ctx.destinations,
                loading: _ctx.loading,
                class: "mb-6",
                onAddStep: _ctx.addStep,
                onDeleteStep: _ctx.deleteStep,
                onUpdateStep: _ctx.updateStep
              }, null, 8, ["value", "action-id", "timeframes", "destinations", "loading", "onAddStep", "onDeleteStep", "onUpdateStep"])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('custom.uhb.management.notes')), 1),
          _createVNode(_component_BaseTextarea, {
            modelValue: _ctx.managementPlan.review_summary_markdown,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.managementPlan.review_summary_markdown) = $event)),
            placeholder: _ctx.$t('custom.uhb.management.enter-management-notes'),
            onChange: _ctx.updateManagementPlanMarkdownDebounce
          }, null, 8, ["modelValue", "placeholder", "onChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}